import { Box } from '@thepuzzlers/pieces';

export const SpringLineFour = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="spring-line-four"
      viewBox="0 0 236 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'red',
        ...sx
      }}
      {...props}>
      <path
        d="M1 51.098C13.8144 45.5958 28.9723 45.7445 38.4958 53.3782C42.3352 56.4515 44.1302 61.5076 47.8199 66.2663C51.9086 71.5207 58.0914 71.7685 62.5291 71.3224C84.2687 69.3396 93.8421 25.6193 85.7645 21.6042C80.9778 19.2248 73.9972 24.6279 68.7618 40.4406C61.9806 60.7145 68.6621 73.6026 73.6981 78.8074C86.8615 92.3399 117.526 60.4667 122.363 28.0482C123.659 19.3735 126.452 8.26997 119.92 2.76776C112.191 -3.67628 104.961 8.7161 103.316 20.3154C100.224 42.1755 109.1 78.4108 122.862 80.8397C136.573 83.2191 150.235 44.4061 148.889 23.1904C148.391 15.4575 145.947 8.36911 140.762 8.96395C135.875 9.50921 133.332 18.2334 132.285 27.4534C130.042 47.7769 136.972 79.2039 154.274 76.3289C174.867 72.9086 194.114 10.9467 174.368 12.5825C161.404 13.7226 164.247 71.719 177.909 75.3375C190.474 78.6587 200.446 61.7059 204.934 45.6949C209.72 28.5439 200.097 21.0589 195.51 32.0138C182.795 62.3999 214.706 78.2621 235 51.2963"
        stroke="#E6592C"
        stroke-miterlimit="10"
      />
    </Box>
  );
};
